<template>
  <div class="fixed bg-[#00000070] top-0 left-0 z-10 w-full h-full">
    <div class="absolute top-0 left-0 bottom-0 right-0 m-auto bg-white w-[300px] h-fit rounded-[10px]">
      <div class="h-[60px] flex items-center justify-center">
        <p
            class="text-[#303030] text-[16px] leading-[60px] px-[10px] m-auto"
            :style="order === 1 ? {color: '#A8221A', fontFamily: 'Suit-Bold'} : {}"
            @click="$emit('change', 1)"
        >최신순</p>
      </div>
      <div class="h-[60px] flex items-center justify-center border-t border-b border-light">
        <p
            class="text-[#303030] text-[16px] leading-[60px] px-[10px] m-auto"
            :style="order === 2 ? {color: '#A8221A', fontFamily: 'Suit-Bold'} : {}"
            @click="$emit('change', 2)"
        >가격 높은 순</p>
      </div>
      <div class="h-[60px] flex items-center justify-center">
        <p
            class="text-[#303030] text-[16px] leading-[60px] px-[10px] m-auto"
            :style="order === 3 ? {color: '#A8221A', fontFamily: 'Suit-Bold'} : {}"
            @click="$emit('change', 3)"
        >가격 낮은 순</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['order']
}
</script>